import * as React from 'react';
import Header from "../components/Header"
import TextComponent from '../components/TextComponent';
import Footer from '../components/Footer';
import NavBar from "../components/NavBar";

export default function Root() {
  return (
    <>

      <div>
        <Header title="ESAME PRATICO" loc="Lugano"  />
        <div className={'containment'} style={{ marginTop: '-7px'}}>
          <NavBar page={'pratico'} />
          <div className='container-description'>
            <div className='triangle-container'>
              <div className='triangle-text'>
                <i></i>
                <TextComponent
                  title={'ESAME PRATICO'}
                  iscrizione={false}
                  cose={'All esame pratico, l esaminatore verifica se il candidato è in grado, anche in situazioni' +
                    ' difficili del traffico, di guidare un veicolo a motore della categoria corrispondente alla licenza secondo le norme della circolazione stradale e tenendo conto degli altri utenti della strada'}
                  costo={'CHF 100'}
                  durata={'45-60 min.'}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}

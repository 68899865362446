import * as React from 'react';
import Header from "../components/Header"
import TextComponent from '../components/TextComponent';
import Footer from '../components/Footer';
import NavBar from "../components/NavBar";
import {useEffect, useState} from "react";
import {Modal, ModalClose, ModalDialog, Typography} from "@mui/joy";
import {Helmet} from "react-helmet";
import ogImage from "../img/ogimage.png";
import ReactGA from "react-ga4";


export default function Root() {
  const [layout, setLayout] = useState('');
  useEffect(() => {
    window.scrollTo(0,0);
    ReactGA.send({ hitType: "pageview", page: "/moto", title: "Corsi moto" });
  },[])
  let modal;
  modal = <Modal open={!!layout} onClose={() => setLayout(undefined)}>
    <ModalDialog
      aria-labelledby="layout-modal-title"
      aria-describedby="layout-modal-description"
      layout={layout}
    >
      <ModalClose/>
      <Typography id="layout-modal-title" component="h2">
        Iscrizione al corso di soccorritori
      </Typography>
      <iframe
        id="edoobox_sgl"
        style={{height: '100%'}}
        src="https://iframe.vku-pgs.asa.ch/it/public/coursegroup/all/VktVXzQxMzQ=/1/pgs/">
        Übersicht anzeigen
      </iframe>
    </ModalDialog>
  </Modal>
  return (
    <>
      <Helmet>
        <title>Lezioni Moto</title>
        <meta name="description" content="Accedi alle Lezioni di Guida della Scuola Guida Lugano"/>
        <meta property="og:title" content="Scuola Guida Lugano - Lezioni di Guida"/>
        <meta property="og:description" content="Accedi alle Lezioni di Guida della Scuola Guida Lugano"/>
        <meta property="og:type" content="video.movie"/>
        <meta property="og:url" content="https://www.scuolaguidalugano.ch"/>
        <meta property="og:image" content={ogImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:domain" content="mysterious-lowlands-94999-f3e66227fc81.herokuapp.com"/>
        <meta property="twitter:url" content="https://mysterious-lowlands-94999-f3e66227fc81.herokuapp.com/"/>
        <meta name="twitter:title" content="Scuola Guida Lugano - Lezioni di Guida"/>
        <meta name="twitter:description" content="Accedi a Lezioni di Guida della Scuola Guida Lugano."/>
        <meta name="twitter:image"
              content="https://res.cloudinary.com/napolinews-it/image/upload/v1687171007/sociali_jhna04.png"/>
      </Helmet>
      <div className='footer-flex'>
        <div className='pagetitle-container'>
          <div className={'containment'}>
            <Header/>
            <NavBar page={'moto'}/>
            <div className='container-description'>
              <div className='triangle-container'>
                <div className='triangle-text'>
                  <i></i>
                  <TextComponent
                    color="#E3D026"
                    title={'CORSI MOTO'}
                    cose={'La formazione pratica di base dura dodici ore ed è composta dai moduli 1-2-3 che devono essere fatti nell\'ordine cronologico.\n' +
                      'Chi ha ottenuto la licenza di condurre della categoria A1 (prima del 01/01/2021) e desidera fare la patente della categoria A 35kW deve completare la formazione svolgendo solo il modulo 3 (4 ore).'}
                    iscrizione={'Per iscriverti, clicca su ”iscriviti” e scorri la lista delle varie date. Una volta individuata la data più consona, procedi con l’inserimento dei dati. Una volta completato il processo, riceverai una e-mail di conferma. Ti aspettiamo a Lugano!'}
                    costo={'3 moduli (completo): 480.- | 1 modulo : 160'}
                    durata={'12 ore (3 moduli)'}
                    button={'moto'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      </>
      );
      }

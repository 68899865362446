/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from 'react';
import {useState} from "react";
import {Button} from '@mui/material'
import {Modal, ModalClose, ModalDialog, Typography} from "@mui/joy";
import corsosocc from "../img/corsosocc.avif";
import ReactGA from "react-ga4";


export default function ƒTextComponent(props) {
  const [layout, setLayout] = useState('');
  let modal;
  if (props.button === 'soccorritori') {
    modal = <Modal open={!!layout} onClose={() => setLayout(undefined)}>
      <ModalDialog
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        layout={layout}
      >
        <ModalClose/>
        <Typography id="layout-modal-title" component="h2">
          Iscrizione al corso di soccorritori
        </Typography>
        <iframe
          noborder
          id="edoobox_sgl"
          style={{height: '100%'}}
          src="https://app1.edoobox.com/sgl/Corsi%20soccorritori%20allievi%20conducenti/Kurs.ed.c8d5cee43509_7390786095.Soccorritori%20allievi%20conducenti%20Mendriso/?edref=sgl">
          Übersicht anzeigen
        </iframe>
      </ModalDialog>
    </Modal>
  } else if (props.button === 'sensibilizzazione') {
    modal = <Modal open={!!layout} onClose={() => setLayout(undefined)}>
      <ModalDialog
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        layout={layout}
        style={{ marginTop: '-61px'}}
      >
        <ModalClose/>
        <Typography id="layout-modal-title" component="h2">
          Iscrizione al corso di sensibilizzazione
        </Typography>
        <iframe
          noborder
          id="edoobox_sgl"
          style={{height: '100%'}}
          src="https://iframe.vku-pgs.asa.ch/it/public/coursegroup/all/VktVXzQxMzQ=/1/vku/">
          Übersicht anzeigen
        </iframe>
        <span
          data-mce-type="bookmark"
          style={{display: 'inline-block;', width: '0px', overflow: 'hidden;', lineHeight: '0'}}
              className="mce_SELRES_start">﻿</span>
        <span
          data-mce-type="bookmark"
          style={{display: 'inline-block', width: '0px', overflow: 'hidden', lineHeight: '0'}}
          className="mce_SELRES_start">﻿</span>
      </ModalDialog>
    </Modal>
  } else if (props.button === 'moto') {
    modal = <Modal open={!!layout} onClose={() => setLayout(undefined)}>
      <ModalDialog
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        layout={layout}
      >
        <ModalClose/>
        <Typography id="layout-modal-title" component="h2">
          Iscrizione al corso di moto
        </Typography>
        <iframe style={{height: '100%'}}
                src="https://iframe.vku-pgs.asa.ch/it/public/coursegroup/all/VktVXzQxMzQ=/1/pgs/"><span
          data-mce-type="bookmark" style={{ display: 'inline-block', width: '0px', overflow: 'hidden', lineHeight: '0'}}
          className="mce_SELRES_start">﻿</span></iframe>
      </ModalDialog>
    </Modal>
  }

  return (<>

    <div>
      <h1>{props.title ? props.title : ''}</h1>
    </div>

    <div>
      <p className={'paragraph'}>
        {props.cose}
      </p>
    </div>

    <div>
      {props.iscrizione ? <h3 style={{color: `${props.color}`}}>Iscrizione</h3> : null}
      <p className={'paragraph'}>
        {props.iscrizione}
      </p>
    </div>

    { props.button === 'soccorritori' ? <>
      <img src={corsosocc} alt="Card" style={{ height: '100px', marginLeft: '-1.2rem', marginTop: '2.2rem'}} />
    </> : null }

    {props.button ?
      <div style={{ margin: '4rem 0'}}>
        <Button
          style={{ width: '100% !important'}}
          variant="contained"
          className={'ochre'}
          size="large"
          onClick={() => {
            if (props.button === 'lezioni') {
              window.open('https://wa.me/+41774442999');
              ReactGA.event({
                category: 'contacted_whatsapp',
                action: 'Click',
                label: 'whatsapp_button',
              });
            } else if (props.button === 'soccorritori') {
              window.open('https://www.corsisoccorritori.ch', '_blank');
              ReactGA.event({
                category: 'opened_corsosoccorritori',
                action: 'Click',
                label: 'iscriviti_button',
              });
            } else {
              setLayout('fullscreen');
              ReactGA.event({
                category: 'opened_sensibilizzazione_event',
                action: 'Click',
                label: 'iscriviti_button',
              });
            }
          }}
        >
          { props.button === 'lezioni' ? 'Contattaci' : 'Iscriviti' }
        </Button>
      </div>
      : <></>
    }

    <div>
      <h3 style={{color: `${props.color}`}}>Durata</h3>
      <p className={'paragraph'}>
        {props.durata}
      </p>
    </div>

    <div className={'cost-flex'} style={{marginBottom: '4rem'}}>
      <h3 style={{color: `${props.color}`}}>Costo</h3>
      <p className={'paragraph'}>
        {props.costo}.-
      </p>

      <div className={'modal-container'}>
        {modal}
      </div>
    </div>
  </>)
}



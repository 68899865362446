import * as React from 'react';
import HeroSubTitle from "./HeroSubTitle";
import {useNavigate} from 'react-router-dom';



export default function HeroList() {
  const navigate = useNavigate();
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  return (
    <>
      <div className="columns-3">
        <div className="bg-red">
          <HeroSubTitle
            title={'SOCCORRITORI'}
            route={'soccorritori'}
            durata={'10 ore'}
            prezzo={'120 CHF'}
            paragraph={'Il corso soccorritori per allievi conducenti ti permette di apprendere le misure salva vita' +
              ' in caso di incidenti stradali, valutare la situazione e adottare misure di primo soccorso.'}
          />
        </div>
        <div>
          <HeroSubTitle
            title={'ESAME TEORICO'} 
            route={'teorico'}
            paragraph={'Scuola guida Lugano si impegna affinché tu possa iscriverti all esame teorico correttamente' +
              ' (su ti.ch).' +
              ' Qualora tu abbia domande, sentiti libero di contattarci.'}
          />
        </div>
        <div className="bg-orange">
          <HeroSubTitle
            title={'SENSIBILIZZAZIONE'}
            route={'sensibilizzazione'}
            durata={'8 ore'}
            prezzo={'200 CHF'}
            paragraph={'Corso guida sensibilizzazione Ticino: sicurezza stradale, normative locali, e consapevolezza' +
              ' del traffico. Approfitta della nostra formazione per guidare in modo responsabile.'}
          />
        </div>

      </div>
      <div className="columns-3">
        <div className="bg-pink">
          <HeroSubTitle
            contatto={true}
            title={'LEZIONI GUIDA'}
            title_2={'Lezione singola'}
            title_3={'Pacchetto 10 lezioni'}
            route={'lezioni'}
            durata={'1 ora'}
            prezzo={'85 CHF'}
            pacchetto={true}
            paragraph={'Per iscriverti, clicca su ”contattaci” e facci sapere quando vorresti iniziare. ' +
              'Una volta individuata la data più consona, ti daremo appuntamento per iniziare. Ti aspettiamo!'}
          />
        </div>
        <div className="">
          <HeroSubTitle
            title={'ESAME PRATICO'}
            route={'pratico'}
            paragraph={'Scuola guida Lugano si impegna affinché tu possa iscriverti all esame pratico in modo' +
              ' preparato e sicuro di te.'}
          />
        </div>
        <div className="bg-orange" style={{marginBottom: '100px'}}>
          <HeroSubTitle
            title={'CORSI MOTO'}
            route={'moto'}
            durata={'12 ore'}
            prezzo={'480 CHF'}
            paragraph={'I nostri corsi moto ti permettono di apprendere a guidare in modo sicuro ed efficiente.' +
              'Cerca una data consona, ed iscriviti adesso!'}
          />
        </div>
      </div>
    </>
  )
}
import logo from "../img/logo.png";
import * as React from "react";
import Button from "@mui/material/Button";

import {useNavigate} from 'react-router-dom';

export default function HeroSubTitle(props) {

  const navigate = useNavigate();
  const navigate1 = () => {
    navigate('/corsi-soccorritori');
  };
  const navigate2 = () => {
    window.open("https://www4.ti.ch/di/sc/conducenti/licenza-allievo-conducente/esami-teorici", '_blank');
  };
  const navigate3 = () => {
    navigate('/sensibilizzazione');
  };
  const navigate4 = () => {
    navigate('/lezioni');
  };
  const navigate5 = () => {
    window.open("https://www4.ti.ch/di/sc/conducenti/licenza-allievo-conducente/esami-pratici", '_blank');
  };
  const navigate6 = () => {
    navigate('/moto');
  };

  return (
    <>
      <div className="cardone">

        <div className="sub-titolone">
          {props.title}
        </div>

        {props.title_2 ? <span className="sub-titolone" style={{ fontSize: '1.6rem', marginBottom: '.2rem'}}>{props.title_2}</span> : ''}


        <div>
          {
            !props.prezzo ?
              '' :
              <div className="para" style={{display: 'flex', justifyContent: 'space-between', marginTop: '-2rem'}}>
                <b>{props.prezzo}</b>
              </div>
          }
        </div>
        {props.title_3 ? <span className="sub-titolone" style={{ fontSize: '1.6rem', marginBottom: '.2rem'}}>{props.title_3}</span> : ''}
        {props.pacchetto ?
          <div className="para" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-2rem' }}>
            <b>800 CHF</b>
          </div> : ''
        }

        <div className="para">
          {props.paragraph}
        </div>
        <div>
          <hr/>
        </div>
        <div className={'alignMobile'}>
          {
            !props.prezzo ?
              '' :
              <Button
                style={{margin: '1rem 1.5rem'}}
                variant="contained"
                className={props.contatto ? 'white landing-button' : 'ochre landing-button'}
                size="large"
                onClick={() => {
                  if (props.route === 'soccorritori') navigate1();
                  if (props.route === 'teorico') navigate2();
                  if (props.route === 'sensibilizzazione') navigate3();
                  if (props.route === 'lezioni') navigate4();
                  if (props.route === 'pratico') navigate5();
                  if (props.route === 'moto') navigate6();
                }}
              >
                {
                  props.route === 'lezioni' ? 'Contattaci' : 'Iscriviti'
                }
              </Button>
          }
        </div>
        <div className={'alignMobile'}>
          {props.route === 'teorico' ?
            <a target={'_blank'} style={{ margin: '0 !important' }} href={'https://www4.ti.ch/di/sc/conducenti/licenza-allievo-conducente/esami-teorici'}>
              <Button
                style={{
                  margin: '1rem .5rem',
                }}
                variant="outlined"
                className={'outlinedz landing-button'}
                size="large">
                iscrizione su ti.ch
              </Button>
            </a> : ''}

          {props.route === 'pratico' ?
            <a target={'_blank'} style={{ margin: '0 !important' }} href={'https://www4.ti.ch/index.php?id=110017'}>
              <Button
                style={{
                  margin: '1rem .5rem',
                }}
                variant="outlined"
                className={'outlinedz landing-button'}
                // onClick={() => {
                //   navigate2();
                // }}
                size="large">
                iscrizione su ti.ch
              </Button>
            </a> : ''}
        </div>
      </div>
    </>
  )
}
